<template>
    
        <div class="px-3 md:px-0 pt-4">

            <p class="text-xl leading-8 font-semibold tracking-tight text-gray-900 capitalize mb-6">
                Pemberitahuan
            </p>

            <div class="-mx-3 md:mx-0">
                <div v-if="pemberitahuanList.length > 0">
                
                    <div @click="loadDetailNotification(data)" v-for="(data, index) in pemberitahuanList" :key="index" class="flex items-center bg-white py-2 px-3 transition-colors cursor-pointer hover:bg-gray-50 border-b">
                        <div class="w-14">
                            <div class="h-14 w-14 rounded-lg content-center flex items-center text-center" :class="data.is_read == 0 ? 'bg-yellow-100' : 'bg-gray-50' ">
                                <img src="../assets/images/bullhorn.svg" class="h-10 w-10 mx-auto"/>
                            </div>
                        </div>
                        <div class="flex-grow ml-3 pt-1 pb-1">
                            <h2 class="font-semibold tracking-tight text-gray-800 md:text-lg text-base">{{ data.judul }}</h2>
                            <p class="text-gray-500 text-xs my-0.5">{{ data.created_at | toHumanDate2 }}</p>
                            <p class="text-gray-900 md:text-base text-sm">{{ data.isi }}</p>
                        </div>
                    </div>
                </div>

                <div class="animate-pulse" v-if="isLoading">
                    <div class="flex bg-white py-2 px-3 rounded-md my-2 transition-colors" v-for="i in 3" :key="i">
                        <div class="h-16 w-16 bg-gray-100 rounded-lg content-center flex items-center text-center">
                        </div>
                        <div class="flex-grow ml-3 pt-1 pb-1">
                            <div class="h-6 bg-gray-100 rounded"></div>
                            <div class="h-5 w-32 mt-2 inline-block mr-2 bg-gray-100 rounded"></div>
                            <div class="h-5 w-40 mt-2 inline-block bg-gray-100 rounded"></div>
                        </div>
                    </div>
                </div>

            </div>
            
            <div v-if="!isLoading && pemberitahuanList.length == 0" class="text-center w-full mt-6" >
                <div class="w-2/3 px-5 mx-auto">
                    <img src="@/assets/images/cloud-computing.svg" class="w-40 mx-auto" />
                    <p class="mt-1 text-gray-800 font-semibold">Belum ada pemberitahuan untuk anda</p>
                </div>
            </div>

        </div>
</template>
<script>
import { combineObject, errorHandler, showErrorNotif } from '../helpers/Tools';
export default {
    name : 'Pemberitahuan',
    data(){
        return {
            isLoading        : true,
            pemberitahuanList: [],
            page             : 1
        }
    },
    mounted() {
        this.getNotification(this.page);
        this.scroll();
    },
    destroyed() {
        window.onscroll = () => {
        }
    },
    methods : {
        refresh(loaded) {
            this.page = 1;
            this.getNotification();
            loaded('done')
        },
        scroll() {
            window.onscroll = () => {
                const bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
                if (bottomOfWindow && !this.isEmpty) {
                    this.getNotification()
                }
            }
        },
        async getNotification() {
            this.isLoading = true;
            try {
                let res = await this.$store.dispatch("getPemberitahuan", {
                    page        : this.page
                });
                this.isLoading = false;
                let result = res.data;
                if(result.status == 'success') {
                    if(result.data.data.length == 0) {
                        this.isEmpty = true;
                    }

                    if(this.page == 1){
                        this.pemberitahuanList = result.data.data;
                    }else{
                        this.pemberitahuanList = combineObject(this.pemberitahuanList, result.data.data, 'id_pemberitahuan');
                    }
                    
                    this.page++;

                } else {
                    this.isEmpty = true;
                    showErrorNotif(this, result.message);
                }
            } catch (error) {
                this.isLoading = false;
                errorHandler(this, error);
            }
        },

        loadDetailNotification(notification) {
            this.$store.dispatch("readNotification", {
                id_pemberitahuan        : notification.id_pemberitahuan
            });
            var payload = notification.payload;
            if(notification.tipe == 1) {
                this.$router.push({
                    name: 'detailModul',
                    params: { 
                        singkatan_kategori: payload.kategori.singkatan.toLowerCase(),
                        tipe_modul        : payload.status_bayar_label,
                        id_paket          : payload.id_paket
                    } 
                });
            }
        }
    }
}
</script>